import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import Faq from "../sections/faq/MentalHealthFaq"
import Footer from "../components/homeNav/footer/Footer"
import BotNav from "../components/bottomNav"
import PageNav from "../components/homeNav/PageNav"


const MentalHealth = () => {
    return (
        <>
            <Seo
                title="Mental Health FAQs | Afyabook"
                description="Frequently asked Questions on Mental Health"
                img="https://res.cloudinary.com/tripleaim-software/image/upload/v1679675006/blog/mentalhealth_mpzefx.webp"
                keywords="Mental Health, mental health disorders,depression, anxiety, bipolar disorder, PTSD, schizophrenia, OCD, ADHD"
                siteUrl="https://www.afyabook.com/mentalHealth"
                canonical="https://www.afyabook.com/mentalHealth"
            ></Seo>
            <PageNav />
            <HeaderContainer>
                <HeroPortfolio>
                    <HeroHeader>Mental Health FAQs</HeroHeader>
                </HeroPortfolio>
            </HeaderContainer>
            <script type="application/ld+json">
                      {`{
                        "@context": "https://schema.org/",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                              "@type": "Question",
                              "name": "What are the most common mental health disorders?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The most common mental health disorders include depression, anxiety, bipolar disorder, PTSD, and schizophrenia."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What are the symptoms of anxiety and how can it be treated?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Symptoms of anxiety include nervousness, restlessness, and a sense of impending doom. Treatment may involve therapy, medication, or a combination of both."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What are the causes of depression and how can it be treated?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Depression can be caused by a variety of factors, including genetics, environment, and life events. Treatment may involve therapy, medication, or a combination of both."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is bipolar disorder and what are the symptoms?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Bipolar disorder is a mental health disorder characterized by extreme mood swings. Symptoms may include manic episodes, depressive episodes, and mixed episodes."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How do I know if I have PTSD and what are the treatment options?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "PTSD is often marked by flashbacks, nightmares, and hypervigilance. Treatment may involve therapy, medication, or a combination of both."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is schizophrenia and how is it treated?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Schizophrenia is a mental health disorder that affects a person's perception of reality. Treatment may involve medication and therapy."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What are the signs of OCD and how can it be treated?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Obsessive-compulsive disorder (OCD) is often marked by repetitive, intrusive thoughts or behaviors. Treatment may involve therapy, medication, or a combination of both."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is ADHD and how is it diagnosed and treated?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Attention-deficit/hyperactivity disorder (ADHD) is a condition that affects a person's ability to focus and stay on task. Diagnosis and treatment may involve a combination of therapy, medication, and lifestyle changes."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How do I find a mental health professional to talk to?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "You can start by contacting your primary care physician or health insurance provider for a referral. You can also search for mental health professionals online or through professional organizations."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How can I support a loved one who is struggling with a mental health disorder?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "It's important to be supportive, non-judgmental, and patient. Encourage them to seek professional help and offer to help them find a mental health professional."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What is the difference between a psychiatrist and a psychologist?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "A psychiatrist is a medical doctor who specializes in mental health and can prescribe medication. A psychologist is a mental health professional who provides therapy and counseling."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "Can mental health disorders be cured?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "While mental health disorders cannot be cured in the traditional sense, they can be effectively managed with treatment."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How can I manage my stress and anxiety?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "You can manage stress and anxiety by practicing relaxation techniques, exercising regularly, getting enough sleep, and seeking professional help if necessary."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How do I know if I need therapy or medication for my mental health?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "This will depend on your individual needs and circumstances. It's best to discuss your options with a mental health professional."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How can I improve my mental health and well-being on a daily basis?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "You can improve your mental health and well-being by engaging in self-care activities, practicing gratitude, and seeking support from loved ones and mental health professionals."
                              }
                            }
                          ]
                      }`}
                    </script>
            <Faq />
            <BotNav />
            <Footer />
        </>
    )
}

export default MentalHealth

const HeaderContainer = styled.div`
    margin-top: 70px;
    width:100%;
`
const HeroPortfolio = styled.div`
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 50vh;
    padding: 0 1rem;
    
`
const HeroHeader = styled.h1`
    font-size: clamp(1.5rem,6vw, 3rem);
`

