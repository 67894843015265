import React, { useState } from "react"
import "./styles/faq.scss"

const listItems = [
  {
    id: 0,
    title: "What are the most common mental health disorders?",
    body: "The most common mental health disorders include depression, anxiety, bipolar disorder, PTSD, and schizophrenia.",
  },
  {
    id: 1,
    title: "What are the symptoms of anxiety and how can it be treated?",
    body: "Symptoms of anxiety include nervousness, restlessness, and a sense of impending doom. Treatment may involve therapy, medication, or a combination of both.",
  },
  {
    id: 3,
    title: "What are the causes of depression and how can it be treated?",
    body: "Depression can be caused by a variety of factors, including genetics, environment, and life events. Treatment may involve therapy, medication, or a combination of both.",
  },
  {
    id: 4,
    title: "What is bipolar disorder and what are the symptoms?",
    body: "Bipolar disorder is a mental health disorder characterized by extreme mood swings. Symptoms may include manic episodes, depressive episodes, and mixed episodes.",
  },
  {
    id: 5,
    title: "How do I know if I have PTSD and what are the treatment options?",
    body: "PTSD is often marked by flashbacks, nightmares, and hypervigilance. Treatment may involve therapy, medication, or a combination of both.",
  },
  {
    id: 6,
    title: "What is schizophrenia and how is it treated?",
    body: "Schizophrenia is a mental health disorder that affects a person's perception of reality. Treatment may involve medication and therapy.",
  },
  {
    id: 7,
    title: "What are the signs of OCD and how can it be treated?",
    body: "Obsessive-compulsive disorder (OCD) is often marked by repetitive, intrusive thoughts or behaviors. Treatment may involve therapy, medication, or a combination of both.",
  },
  {
    id: 8,
    title:"What is ADHD and how is it diagnosed and treated?",
    body: "Attention-deficit/hyperactivity disorder (ADHD) is a condition that affects a person's ability to focus and stay on task. Diagnosis and treatment may involve a combination of therapy, medication, and lifestyle changes.",
  },
  {
    id: 9,
    title:"How do I find a mental health professional to talk to?",
    body: "You can start by contacting your primary care physician or health insurance provider for a referral. You can also search for mental health professionals online or through professional organizations.",
  },
  {
    id: 10,
    title: "How can I support a loved one who is struggling with a mental health disorder?",
    body: "It's important to be supportive, non-judgmental, and patient. Encourage them to seek professional help and offer to help them find a mental health professional.",
  },
  {
    id: 11,
    title:"What is the difference between a psychiatrist and a psychologist?",
    body: "A psychiatrist is a medical doctor who specializes in mental health and can prescribe medication. A psychologist is a mental health professional who provides therapy and counseling.",
  },
  {
    id: 12,
    title:"Can mental health disorders be cured?",
    body: "While mental health disorders cannot be cured in the traditional sense, they can be effectively managed with treatment.",
  },
  {
    id: 13,
    title:"How can I manage my stress and anxiety?",
    body: "You can manage stress and anxiety by practicing relaxation techniques, exercising regularly, getting enough sleep, and seeking professional help if necessary.",
  },
  {
    id: 14,
    title:"How do I know if I need therapy or medication for my mental health?",
    body: "This will depend on your individual needs and circumstances. It's best to discuss your options with a mental health professional.",
  },
  {
    id: 15,
    title:"How can I improve my mental health and well-being on a daily basis?",
    body: "You can improve your mental health and well-being by engaging in self-care activities, practicing gratitude, and seeking support from loved ones and mental health professionals.",
  }, 
]
export default function Faq() {
  const [active, setActive] = useState(null)
  return (
    <div className="faq">
      <section className="list">
        <div className="faqheader">
          <h1 className="heading">Frequent Questions our clients ask about Mental Health</h1>
        </div>
        {listItems.map(item => (
          <button
            key={item.id}
            className="list-item"
            open={active === item.id || false}
            onClick={e => {
              e.preventDefault()
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
          >
            <header className="list-item-title">
              <p>{item.title}</p>
              <i className="list-item-plus-icon" />
            </header>
            <div className="list-item-body">
              <article className="list-item-article">{item.body}</article>
            </div>
          </button>
        ))}
      </section>
    </div>
  )
}
